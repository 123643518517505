import React from "react"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import './Functionality.scoped.scss'

interface Props {
  type: string
}

/**
 * 機能性の表示.
 */
const Functionality: React.FC<Props> = ({ type }) => {

  return (
    <img src={`/images/func-${type}.png`} alt={type} className="image"/>
  )
}
export default Functionality
