/**
 * APIの基底クラス.
 */
import UnauthorizedException from '../exceptions/UnauthorizedException'
import ServerErrorException from '../exceptions/ServerErrorException'

const RESPONSE_TYPE_JSON = 'json'
const RESPONSE_TYPE_BLOB = 'blob'

type ApiParams = {
  endpoint: string,
  options: any | null,
  credential: boolean
  responseType: 'json' | 'blob'
}

export default class AbstractApi {

  async _get(endpoint: string, params: any, credential=true) {

    params = params || {}

    let paramString
    if (params) {
      paramString = Object.keys(params)
        .filter(key => params[key] != null)
        .map(key => {
          return `${key}=${params[key]}`
        }).join('&')
    }

    if (paramString) {
      endpoint = endpoint + '?' + paramString
    }

    // キャッシュバスターを追加.
    const cacheBuster = (new Date()).getTime()
    if (endpoint.indexOf('?') !== -1) {
      endpoint += '&_=' + cacheBuster
    } else {
      endpoint += '?_=' + cacheBuster
    }

    return this.api({ endpoint, options: null, credential, responseType: RESPONSE_TYPE_JSON })
  }

  async _post(endpoint: string, data: any, credential=true) {

    const options = {
      method : 'POST',
      body   : data ? JSON.stringify(data) : null,
      headers : new Headers({
        'Content-Type' : 'application/json'
      })
    }
    return this.api({ endpoint, options, credential, responseType: RESPONSE_TYPE_JSON })
  }

  async _postForm(endpoint: string, formData: any, credential=true) {

    const options = {
      method : 'POST',
      body   : formData,
      headers : new Headers({
        'Accept' : 'application/json'
      })
    }
    return this.api({ endpoint, options, credential, responseType: RESPONSE_TYPE_JSON })
  }

  async _put(endpoint: string, data: any, credential=true) {

    const options = {
      method : 'PUT',
      body   : data ? JSON.stringify(data) : null,
      headers : new Headers({
        'Content-Type' : 'application/json'
      })
    }
    return this.api({ endpoint, options, credential, responseType: RESPONSE_TYPE_JSON })
  }

  async _getBlob(endpoint: string, params: any, credential=true) {

    let paramString
    if (params) {
      paramString = Object.keys(params)
        .filter(key => params[key])
        .map(key => {
          return `${key}=${params[key]}`
        }).join('&')
    }

    if (paramString) {
      endpoint = endpoint + '?' + paramString
    }

    return this.api({ endpoint, options: null, credential, responseType : RESPONSE_TYPE_BLOB })
  }

  async _delete(endpoint: string, params: any, credential=true) {

    let paramString
    if (params) {
      paramString = Object.keys(params)
        .filter(key => params[key])
        .map(key => {
          return `${key}=${params[key]}`
        }).join('&')
    }

    if (paramString) {
      endpoint = endpoint + '?' + paramString
    }

    const options = {
      method : 'DELETE',
      headers : new Headers({
        'Content-Type' : 'application/json'
      })
    }

    return this.api({ endpoint, options, credential, responseType: RESPONSE_TYPE_JSON })
  }

  async api({
              endpoint,
              options = null,
              credential= true,
              responseType= RESPONSE_TYPE_JSON
  }: ApiParams) {

    try {

      options = options || {}
      options.headers = options.headers || new Headers()
      options.headers.set('Accept', 'application/json')

      if (credential) {
        options = await this.addCredentialHeader(options)
      }
      console.log('fetch:', process.env.REACT_APP_API_ROOT + endpoint, options)
      const res = await fetch(process.env.REACT_APP_API_ROOT + endpoint, options)
      const statusCode = res.status
      if (res.status >= 500) {
        console.log('Server ERROR:', res)
        const text = await res.text()
        throw new ServerErrorException(text || 'Server error occurred.')
      }
      if (res.status >= 401 && res.status !== 404) {
        console.log('Unauthorized ERROR:', res)
        const text = await res.text()
        throw new UnauthorizedException(text || 'Server error occurred.')
      }

      let body: any|null = null
      if (responseType === RESPONSE_TYPE_BLOB) {
        // バイナリーの場合.
        body = await res.blob()
      } else {
        // テキスト（JSON文字列）の場合.
        try {
          body = await res.json()
        } catch (e) {
          console.log('res.json() failed.', e)
        }
      }

      return { statusCode, body }

    } catch (e) {
      console.log('ERROR:', e)
      throw e
    }

  }

  async addCredentialHeader(options: any|null) {
    // TODO 初期リリースでは認証はないため、対応しない.
    // const apiToken = await AppPreference.getApiToken()
    // if (!apiToken) {
    //   return options
    // }
    // options = options || {}
    // options.headers = options.headers || new Headers()
    // options.headers.set('Authorization', `Bearer ${apiToken}`)
    // return options
    return options
  }

}
