import  { Action } from 'redux'
import {Item, ItemOption, ItemOptionGroup, ItemSku, OrderItem, OrderType, Shop} from "../../type"

/**
 * アクション識別ラベル.
 */
export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_ORDER_ITEM = 'CREATE_ORDER_ITEM'
export const DELETE_CURRENT_ORDER_ITEM = 'DELETE_CURRENT_ORDER_ITEM'
export const UPDATE_ORDER_ITEM_SKU = 'UPDATE_ORDER_ITEM_SKU'
export const UPDATE_DRESSING_MIXED = 'UPDATE_DRESSING_MIXED'
export const UPDATE_SOUP_WARMED = 'UPDATE_SOUP_WARMED'
export const ADD_ORDER_ITEM_OPTION = 'ADD_ORDER_ITEM_OPTION'
export const DELETE_ORDER_ITEM_OPTION = 'DELETE_ORDER_ITEM_OPTION'
export const ADD_CART = 'ADD_CART'
export const RESET_CART = 'RESET_CART'
export const CHANGE_ORDER_ITEM_AMOUNT = 'CHANGE_ORDER_ITEM_AMOUNT'
export const CHANGE_ORDER_ITEM = 'CHANGE_ORDER_ITEM'
export const DELETE_ORDER_ITEM = 'DELETE_ORDER_ITEM'
export const SET_BAG_NUM = 'SET_BAG_NUM'

// 注文作成.
export interface ICreateOrder extends Action {
  orderType: OrderType
  shop: Shop
}
export const createCreateOrderAction = (orderType: OrderType, shop: Shop): ICreateOrder => {
  return { type: CREATE_ORDER, orderType, shop }
}

// 注文アイテム作成.
export interface ICreateOrderItem extends Action {
  item: Item
}
export const createCreateOrderItemAction = (item: Item): ICreateOrderItem => {
  return { type: CREATE_ORDER_ITEM, item }
}

// 現在の注文アイテム削除.
export interface IDeleteCurrentOrderItem extends Action {}
export const createDeleteCurrentOrderItemAction = (): IDeleteCurrentOrderItem => {
  return { type: DELETE_CURRENT_ORDER_ITEM }
}

// 注文アイテムのSKU変更.
export interface IUpdateOrderItemSku extends Action {
  sku: ItemSku
}
export const createUpdateOrderItemSkuAction = (sku: ItemSku): IUpdateOrderItemSku => {
  return { type: UPDATE_ORDER_ITEM_SKU, sku }
}

// ドレッシング和える選択の変更.
export interface IUpdateDressingMixed extends Action {
  mixed: boolean
}
export const createUpdateDressingMixedAction = (mixed: boolean): IUpdateDressingMixed => {
  return { type: UPDATE_DRESSING_MIXED, mixed }
}

// 注文アイテムの「スープを温める」を変更.
export interface IUpdateSoupWarmed extends Action {
  soupWarmed: boolean
}
export const createUpdateSoupWarmedAction = (soupWarmed: boolean): IUpdateSoupWarmed => {
  return { type: UPDATE_SOUP_WARMED, soupWarmed }
}

// 注文アイテムのオプション追加.
export interface IAddOrderItemOption extends Action {
  optionGroup: ItemOptionGroup
  itemOption: ItemOption
  num: number | undefined
}
export const createAddOrderItemOptionAction = (optionGroup: ItemOptionGroup, itemOption: ItemOption, num: number | undefined): IAddOrderItemOption => {
  return { type: ADD_ORDER_ITEM_OPTION, optionGroup, itemOption, num }
}

// 注文アイテムのオプション削除.
export interface IDeleteOrderItemOption extends Action {
  itemOption: ItemOption
}
export const createDeleteOrderItemOptionAction = (itemOption: ItemOption): IDeleteOrderItemOption => {
  return { type: DELETE_ORDER_ITEM_OPTION, itemOption }
}

// 現在のオーダーアイテムをカートに追加.
export interface IAddCart extends Action {}
export const createAddCartAction = (): IAddCart => {
  return { type: ADD_CART }
}

// カートをクリアする.
export interface IResetCart extends Action {}
export const createResetCartAction = (): IResetCart => {
  return { type: RESET_CART }
}

// オーダーアイテムの数量変更.
export interface IChangeOrderItemAmount extends Action {
  orderItemKey: string
  amount: number
}
export const createChangeOrderItemAmountAction = (orderItemKey: string, amount: number): IChangeOrderItemAmount => {
  return { type: CHANGE_ORDER_ITEM_AMOUNT, orderItemKey, amount }
}

// オーダーアイテムを削除.
export interface IDeleteOrderItem extends Action {
  orderItemKey: string
}
export const createDeleteOrderItemAction = (orderItemKey: string): IDeleteOrderItem => {
  return { type: DELETE_ORDER_ITEM, orderItemKey }
}

// オーダーアイテムの変更.
export interface IChangeOrderItem extends Action {
  orderItem: OrderItem
}
export const createChangeOrderItemAction = (orderItem: OrderItem): IChangeOrderItem => {
  return { type: CHANGE_ORDER_ITEM, orderItem }
}

// 紙袋の数を設定.
export interface ISetBagNum extends Action {
  num: number
  item: Item
}
export const createSetBagNumAction = (num: number, item: Item): ISetBagNum => {
  return { type: SET_BAG_NUM, num, item }
}
