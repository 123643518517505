/**
 * 店舗トップ.
 */
import React, {useEffect, useState} from "react"
import './Order.scoped.scss'
import {useHistory, useParams} from "react-router-dom"
import useInterval from 'use-interval'
import {useAppDispatch, useAppSelector} from "../../redux/hooks"
import {RootState} from "../../redux/Store"
import AppHeader from "../../components/organisms/headers/AppHeader"
import {OrderItem, OrderItemOption, WebOrderResponse} from "../../type"
import ItemUtils from "../../utils/ItemUtils"
import OrderApi from "../../apis/OrderApi"
import {
  ITEM_KEY_BAG,
  ORDER_TYPE_TAKE_OUT,
  SESSION_STORAGE_KEY_ORDER_ID,
  SESSION_STORAGE_KEY_ORDER_NO
} from "../../AppConst"
import {
  createChangeOrderItemAction,
  createChangeOrderItemAmountAction,
  createDeleteOrderItemAction,
  createSetBagNumAction
} from "../../redux/actions/OrderAction"
import BagDialog from "../../components/organisms/bagDialog/BagDialog"
import ItemApi from "../../apis/ItemApi"

interface ParamTypes {
  key: string
}

export default function ShopOrderPage() {
  const history = useHistory()
  const dispatch  = useAppDispatch()
  const { key } = useParams<ParamTypes>();
  // 店舗情報を取得.
  const shop = useAppSelector((state: RootState) => state.shop.shop)
  // 注文情報を取得.
  const order = useAppSelector((state: RootState) => state.order.order)
  // Web注文キー
  const [ webOrderKey, setWebOrderKey ] = useState<string | undefined>(undefined)
  // QRコード
  const [ qrCode, setQrCode ] = useState<string | undefined>(undefined)
  // 要望
  const [ remarks, setRemarks ] = useState<string>('')
  // エラーメッセージ
  const [ errorMessage, setErrorMessage ] = useState<string>('')
  // 紙バックの必要枚数を聞いたか.
  const [ askedBag, setAskedBag ] = useState<boolean>(false)

  const createWebOrder = async () => {
    if (!order) {
      history.push(`/shops/${key}`)
      return null
    }
    const { statusCode, body } = await (new OrderApi()).add(order)
    if (statusCode !== 200) {
      setErrorMessage(
        (body && body.message) ? body.message : 'エラーが発生しました。時間をおいて再度お試しください。'
      )
      return
    }
    const response = body as WebOrderResponse
    setQrCode(response.qrCode || undefined)
    setWebOrderKey(response.key || undefined)
    setTimeout(() => {
      console.log('response1:', response.key, webOrderKey)
    },100)
  }

  // ユーザーコメントの入力.
  let [timerId, setTimerId] = useState<any>(null)
  const updateRemarks = (text: string) => {
    setRemarks(text)
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(updateWebOrder, 1000)
    setTimerId(timerId)
  }

  // ユーザーメモをサーバーに保存.
  const updateWebOrder = async () => {
    console.log('fetchWebOrder:', webOrderKey, qrCode)
    if (!webOrderKey) return
    try {
      await (new OrderApi()).update(webOrderKey, { remarks })
    } catch (e) {
      window.alert('ご要望の保存に失敗しました。会計時に直接、店員へお伝えください。')
    }
  }

  // 数量を変更.
  const changeOrderItemAmount = (orderItemKey: string, amount: number) => {
    dispatch(createChangeOrderItemAmountAction(orderItemKey, amount))
  }

  // オーダーアイテムの編集.
  const changeOrderItem = (orderItem: OrderItem) => {
    dispatch(createChangeOrderItemAction(orderItem))
    history.push(`/shops/${key}/items/${orderItem.item.key}`)
  }

  // オーダーアイテムを削除.
  const deleteOrderItem = (orderItemKey: string) => {
    if (!window.confirm('削除します。よろしいですか？')) return
    dispatch(createDeleteOrderItemAction(orderItemKey))
  }

  // 確定した注文のID、No.
  const [ orderId, setOrderId ] = useState<number | null>(null)
  const [ orderNo, setOrderNo ] = useState<number | null>(null)

  // Web注文の状況を確認する.
  const fetchWebOrder = async () => {
    console.log('fetchWebOrder:', webOrderKey)
    if (!webOrderKey) return
    if (orderId) return
    try {
      const webOrder = await (new OrderApi()).get(webOrderKey)
      if (webOrder.orderId) {
        setOrderId(webOrder.orderId)
        setOrderNo(webOrder.no)
        sessionStorage.setItem(SESSION_STORAGE_KEY_ORDER_ID, String(webOrder.orderId))
        sessionStorage.setItem(SESSION_STORAGE_KEY_ORDER_NO, String(webOrder.no))
      }
    } catch (e) {
      // 5秒ごとに呼び出されるので、コンソール出力くらいにしておく.
      console.log('ERROR:fetchWebOrder:', e)
    }
  }

  // 商品名を生成します.
  // サイズ表記が必要な場合は、それも付与します.
  const renderItemName = (orderItem: OrderItem): string => {
    let name = orderItem.item.name
    if (orderItem.sku && orderItem.sku.name) {
      name +=`(${orderItem.sku.name})`
    }
    // サーバーからのレスポンス時にも対応（プロパティ名は合わせたいな〜）.
    else if (orderItem.itemSku && orderItem.itemSku.name) {
      name +=`(${orderItem.itemSku.name})`
    }
    return name
  }

  // 5秒ごとに注文の状態を取得します.
  useInterval(fetchWebOrder, 5000)

  // 画面表示時.
  useEffect(() => {
    // 画面リロード対策（既に注文済みの場合）.
    let shouldMakeWebOrder = true
    const orderIdString = sessionStorage.getItem(SESSION_STORAGE_KEY_ORDER_ID)
    if (orderIdString) {
      const previousOrderId = parseInt(orderIdString, 10)
      if (!isNaN(previousOrderId)) {
        setOrderId(previousOrderId)
        shouldMakeWebOrder = false
      }
    }
    const orderNoString = sessionStorage.getItem(SESSION_STORAGE_KEY_ORDER_NO)
    if (orderNoString) {
      const previousOrderNo = parseInt(orderNoString, 10)
      if (!isNaN(previousOrderNo)) {
        setOrderNo(previousOrderNo)
      }
    }
    // 画面表示時に、Webオーダーを作成し、QRコードを生成する.
    if (shouldMakeWebOrder) {
      createWebOrder()
    }
    // 以下、Unmount時の処理.
    return () => {
      console.log('unmounted')
    }
  }, [key]);

  // オーダー内容が変更された時.
  useEffect(() => {
    console.log('orderChanged!!')
    createWebOrder()
  }, [order])

  // トップに戻る.
  const goTop = () => {
    history.push(`/shops/${key}`)
  }

  // 必要な情報がない場合は、店舗詳細へ戻る.
  if (!shop || !order) {
    history.push(`/shops/${key}`)
    return null
  }

  // 紙バッグのダイアログを閉じた.
  const onCloseBagDialog = () => {
    setAskedBag(true)
  }

  // 紙バッグダイアログで紙袋の枚数を選択した.
  const onConfirmBagDialog = async (num: number) => {
    setAskedBag(true)
    // 紙袋データを取得.
    const item = await (new ItemApi()).get(ITEM_KEY_BAG)
    if (!item) {
      return window.alert('商品データの取得に失敗しました。OE000001')
    }
    // 紙袋を商品として追加.
    dispatch(createSetBagNumAction(num, item))
  }

  return (
    <>
      <AppHeader
        title={shop?.name}
        leftVisible={true}
        leftCallback={ () => history.goBack() }/>
      <div className="c-container">
        { errorMessage && (
          <div className="notice c-red">{ errorMessage }</div>
        )}

        {/*注文未確定の場合*/}
        {
          !orderId && (
            <>
              {/*QRコードの表示.*/}
              {
                qrCode && (
                  <>
                    <div className="notice">下記QRコードをレジで提示して<br/>決済してください。</div>
                    <div className="text-center qr" dangerouslySetInnerHTML={{ __html: qrCode }}>
                    </div>
                  </>
                )
              }
              {/*ローディング表示.*/}
              {
                (!errorMessage && !qrCode) && (
                  <div className="notice">Now Loading...</div>
                )
              }
              {/*ご要望*/}
              <div className="heading">ご要望</div>
              <p className="c-fs-14">ご要望がある場合、下記にご記入ください。</p>
              <textarea
                className={`form-control c-fs-14`}
                placeholder="ご要望（最大100文字まで）"
                onChange={ e => updateRemarks(e.target.value) }></textarea>
              {/*紙袋の確認（テイクアウトのみ）*/}
              {
                !askedBag && order.orderType === ORDER_TYPE_TAKE_OUT && (
                  <BagDialog onConfirm={onConfirmBagDialog} onClose={onCloseBagDialog}/>
                )
              }
            </>
          )
        }

        {/*注文確定後.*/}
        {
          orderId && (
            <>
              <div className="notice mb-3">ご注文ありがとうございます。</div>
              <div className="notice mb-3">注文番号は<span className="orderId">{ orderNo }</span>です。</div>
              <div className="notice mb-5">(ID:{ orderId })</div>
              <div className="mb-5">
                <button className="c-btn size-l" onClick={goTop}>
                  <span className="pl-1">オーダーを開始する</span>
                </button>
              </div>
            </>
          )
        }

        {/*注文内容*/}
        <div className="heading">注文内容</div>
        <div className="orderItems">
          {
            (order.orderItems || []).map((orderItem: OrderItem, i: number) => {

              return (
                <div className="itemContainer" key={i}>
                  <img className="itemImage" src={orderItem.item.imageUrl}/>
                  <div className="itemContainerRight">
                    <div className="itemContainerRightTexts">
                      <div className="itemName">{ renderItemName(orderItem) }</div>
                      <div className="c-mt-8">
                        {
                          (orderItem.orderItemOptions || []).map((orderItemOption: OrderItemOption, i: number) => {
                            return (
                              <div key={i}>
                                <div className="optionName">
                                  { orderItemOption.itemOption?.item?.name }
                                  { orderItemOption.itemOption?.item?.skus[0].name }
                                  { orderItemOption.amount >= 2 && `x ${orderItemOption.amount}`}
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className="c-mt-8">
                        <div className="itemPrice">¥{ ItemUtils.getOrderItemPrice(orderItem).toLocaleString() }(税込)</div>
                      </div>
                    </div>

                    {/*ボタン類（注文確定前のみ表示）*/}
                    {
                      !orderId && (
                        <div className="itemButtons">
                          <div className="btnPlusMinus">
                            <span className="material-icons c-fs-18" onClick={() => changeOrderItemAmount(orderItem.key, orderItem.amount - 1)}>remove_circle_outline</span>
                            <span className="itemAmount">{ orderItem.amount }</span>
                            <span className="material-icons c-fs-18" onClick={() => changeOrderItemAmount(orderItem.key, orderItem.amount + 1)}>add_circle_outline</span>
                          </div>
                          <div className="spacer"></div>
                          <div className="btnCancelContainer c-mt-16" onClick={() => changeOrderItem(orderItem)}>
                            <span className="material-icons c-fs-18">shopping_bag</span>
                            <span className=".btnCancelText">編集</span>
                          </div>
                          <div className="btnCancelContainer c-mt-16" onClick={() => deleteOrderItem(orderItem.key)}>
                            <span className="material-icons c-fs-18">close</span>
                            <span className="btnCancelText">削除</span>
                          </div>
                        </div>
                      )
                    }
                  </div>

                </div>
              )
            })
          }
        </div>


      </div>
    </>
  )
}
