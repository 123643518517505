import {ItemOption, ItemOptionGroup, OrderItem, OrderItemOption, OrderStatus} from "../type"
import {
  ITEM_OPTION_GROUP_ACTION_TYPE_FIRST_FREE,
  ITEM_OPTION_GROUP_ACTION_TYPE_ONLY_ONE,
  ITEM_OPTION_GROUP_ACTION_TYPE_TWO_FREE,
  ORDER_STATUS_COOKED,
  ORDER_STATUS_COOKING,
  ORDER_STATUS_DONE,
  ORDER_STATUS_NOT_YET
} from "../AppConst"

/**
 * 注文関連の便利機能.
 */
export default class OrderUtils {

  static getOrderStatusText(status: OrderStatus): string {
    switch (status) {
      case ORDER_STATUS_NOT_YET: return '調理前'
      case ORDER_STATUS_COOKING: return '調理中'
      case ORDER_STATUS_COOKED: return '受渡前'
      case ORDER_STATUS_DONE: return '完了'
      default: return '完了'
    }
  }

  /**
   * オプションを追加します.
   */
  static addOptionToOrderItem(
    orderItem: OrderItem,
    itemOptionGroup: ItemOptionGroup,
    itemOption: ItemOption,
    num: number | undefined) {

    // 初めて追加するように、配列を準備しておく.
    if (!orderItem.orderItemOptions) {
      orderItem.orderItemOptions = []
    }

    // 個数追加か新規追加かを判断する.
    // 個数追加の場合は、個数をインクリメントする（個数指定があればそれを代入する）.
    let found = false
    orderItem.orderItemOptions.forEach(orderItemOption => {
      // 個数追加で対応.
      if (orderItemOption.itemOption.id === itemOption.id && orderItemOption.skuId === itemOption.item.skus[0].id) {
        let nextAmount = (num != null ? num : orderItemOption.amount + 1)
        // 1つのみ選択可能な場合は、数量は「1」固定.
        if (itemOptionGroup.actionType === ITEM_OPTION_GROUP_ACTION_TYPE_ONLY_ONE) {
          nextAmount = 1
        }
        // 最大数を超えない場合は追加OK.
        if (nextAmount <= itemOption.maxCount) {
          orderItemOption.amount = nextAmount
        }
        // 2つまで無料の対応.
        if (itemOptionGroup.actionType === ITEM_OPTION_GROUP_ACTION_TYPE_TWO_FREE) {
          let restFreeAmount = 2
          // 同一オプショングループで、自分以外の無料個数を数える.
          itemOptionGroup.options.forEach((tmp1: ItemOption) => {
            // 自分自身は除外.
            if (tmp1.id === itemOption.id) return
            orderItem.orderItemOptions.forEach((tmp2: OrderItemOption) => {
              if (tmp1.id === tmp2.itemOption.id) {
                restFreeAmount -= tmp2.freeAmount
              }
            })
          })
          if (restFreeAmount > 0) {
            orderItemOption.freeAmount = Math.min(restFreeAmount, orderItemOption.amount)
          }
        }
        found = true
      }
    })

    // 新規追加する
    if (!found) {
      // 1つのみ選択可能な場合に、他の選択があれば、それは削除する.
      if (itemOptionGroup.actionType === ITEM_OPTION_GROUP_ACTION_TYPE_ONLY_ONE) {
        orderItem.orderItemOptions = orderItem.orderItemOptions.filter((orderItemOption: OrderItemOption) => {
          return orderItemOption.itemOption.itemOptionGroupId !== itemOptionGroup.id
        })
      }
      // 1つ目のみ無料かを判定する.
      let freeAmount = 0
      if (itemOptionGroup.actionType === ITEM_OPTION_GROUP_ACTION_TYPE_FIRST_FREE) {
        freeAmount = 1
        // 他に追加されていないかを確認する.
        itemOptionGroup.options.forEach((tmp1: ItemOption) => {
          orderItem.orderItemOptions.forEach((tmp2: OrderItemOption) => {
            if (tmp1.id === tmp2.itemOption.id) {
              freeAmount = 0
            }
          })
        })
      }
      // 2つまで無料の場合.
      if (itemOptionGroup.actionType === ITEM_OPTION_GROUP_ACTION_TYPE_TWO_FREE) {
        let restFreeAmount = 2
        // 同一オプショングループで、他の追加状況を確認します.
        itemOptionGroup.options.forEach((tmp1: ItemOption) => {
          orderItem.orderItemOptions.forEach((tmp2: OrderItemOption) => {
            if (tmp1.id === tmp2.itemOption.id) {
              restFreeAmount -= tmp2.freeAmount
            }
          })
        })
        if (restFreeAmount > 0) {
          freeAmount = 1
        }
      }
      // アイテムオプションを追加.
      orderItem.orderItemOptions.push({
        price: 0,
        itemOption,
        // デフォで1つ目のSKUを選んでおく.
        sku: itemOption.item.skus[0],
        amount: 1,
        freeAmount,
        skuId: itemOption.item.skus[0].id,
      })
    }

    return orderItem
  }


}
