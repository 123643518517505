import React from "react"
import './BagDialog.scoped.scss'

interface Props {
  onConfirm?: (numOfBags: number) => void
  onClose?: () => void
}

/**
 * 紙袋が必要かを確認するダイアログ.
 */
const BagDialog: React.FC<Props> = ({ onConfirm, onClose }) => {

  const onChange = (e: any) => {
    const value = Number(e.target.value)
    onConfirm && onConfirm(value)
  }

  return (
    <div className="root">
      <div className="content">

        {/*閉じるボタン*/}
        <div className="close" onClick={() => onClose && onClose()}>
          <span className="closeIcon material-icons">close</span>
        </div>

        {/*タイトル*/}
        <div className="name">紙袋は必要ですか？</div>

        {/*説明*/}
        <div className="description">
          必要な枚数を選択してください。1つの紙袋にサラダ2つ入ります。
        </div>

        {/*選択肢*/}
        <select className="form-control form-control-lg mt-3" onChange={onChange}>
          <option value="0">== 選択してください ==</option>
          <option value="0">不要</option>
          <option value="1">1枚</option>
          <option value="2">2枚</option>
          <option value="3">3枚</option>
          <option value="4">4枚</option>
          <option value="5">5枚</option>
        </select>

      </div>
    </div>
  )
}
export default BagDialog
