import AbstractApi from "./AbstractApi"
import ServerErrorException from "../exceptions/ServerErrorException"
import {Item} from "../type"
import NotFoundException from "../exceptions/NotFoundException"

export default class ItemApi extends AbstractApi {

  async list(shopKey: string): Promise<Item[]> {
    const { statusCode, body } = await this._get(`/api/web/shops/${shopKey}/items`, {})
    if (statusCode === 404) {
      throw new NotFoundException()
    }
    if (statusCode !== 200) {
      throw new ServerErrorException()
    }
    return body as Item[]
  }

  async get(key: string): Promise<Item | null> {
    const { statusCode, body } = await this._get(`/api/web/items/${key}`, {})
    if (statusCode !== 200) {
      throw new ServerErrorException()
    }
    return body as Item
  }


}
