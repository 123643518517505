/**
 * 店舗トップ.
 */
import React, {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
// import LocalMall from '@material-ui/icons/LocalMall
import ShopApi from "../../apis/ShopApi"
import {useAppDispatch, useAppSelector} from '../../redux/hooks'
import {RootState} from "../../redux/Store"
import {createSetShopAction} from "../../redux/actions/ShopAction"
import AppHeader from "../../components/organisms/headers/AppHeader"
import './Top.scoped.scss'
import NotFoundException from "../../exceptions/NotFoundException"
import {OrderType} from "../../type"
import {createCreateOrderAction, createResetCartAction} from "../../redux/actions/OrderAction"
import {
  ORDER_TYPE_EAT_IN,
  ORDER_TYPE_TAKE_OUT,
  SESSION_STORAGE_KEY_ORDER_ID,
  SESSION_STORAGE_KEY_ORDER_NO
} from "../../AppConst"

interface ParamTypes {
  key: string
}

export default function ShopTopPage() {
  const history = useHistory()
  const dispatch  = useAppDispatch()
  const { key } = useParams<ParamTypes>();
  const [ notFound, setNotFound ] = useState<boolean>(false)

  // ページロード時に、店舗情報を読み込み、カート情報をリセットします.
  useEffect(() => {
    const fetchShop = async () => {
      // カート情報をリセット.
      dispatch(createResetCartAction())
      // 注文情報もリセット.
      sessionStorage.removeItem(SESSION_STORAGE_KEY_ORDER_ID)
      sessionStorage.removeItem(SESSION_STORAGE_KEY_ORDER_NO)

      try {
        const shop = await (new ShopApi()).detail(key)
        dispatch(createSetShopAction(shop))
      } catch (e) {
        if (e instanceof NotFoundException) {
          setNotFound(true)
        } else {
          // サーバーエラーページへ
          history.push('/error')
        }
      }

    }
    fetchShop()
  }, [key]);

  // 店舗情報を取得.
  const shop = useAppSelector((state: RootState) => state.shop.shop)

  // 注文方法の選択をクリック.
  const onClickOrderType = (orderType: OrderType) => {
    if (!shop) {
      return
    }
    dispatch(createCreateOrderAction(orderType, shop))

    // 商品一覧へ.
    history.push(`/shops/${key}/items`)
  }

  // 店舗が存在しない時（URLが不正など）
  if (notFound) {
    return (
      <>
        <AppHeader/>
        <div className="c-container text-center">
          <div className="welcome">該当のお店が見つかりませんmm<br/>お手数ですが最初からお試しください。</div>
        </div>
      </>
    )
  }

  // 読み込み前は画面に表示しない.
  if (!shop) {
    return null
  }

  return (
    <>
      <AppHeader title={'High Five Salad'}/>
      <div className="c-container text-center">
        <div className="emoji">🎉</div>
        <div className="welcome">ようこそ、<span className="shopName">{ shop.name } </span>へ！</div>
        <div className="mt-2">まずは、ご利用方法を選択してください。</div>
        <div className="text-center mt-5">
          <button className="c-btn" onClick={onClickOrderType.bind(null, ORDER_TYPE_TAKE_OUT)}>
            <span className="material-icons c-yellow">shopping_bag</span>
            <span className="pl-1">テイクアウト</span>
          </button>
          <button className="c-btn mt-4" onClick={onClickOrderType.bind(null, ORDER_TYPE_EAT_IN)}>
            <span className="material-icons c-yellow">restaurant</span>
            <span className="pl-1">イートイン</span>
          </button>
        </div>
      </div>
    </>
  )
}
