import AbstractApi from "./AbstractApi"
import ServerErrorException from "../exceptions/ServerErrorException"
import {Shop} from "../type"
import NotFoundException from "../exceptions/NotFoundException"

export default class ShopApi extends AbstractApi {

  async detail(key: string): Promise<Shop> {
    const { statusCode, body } = await this._get(`/api/web/shops/${key}`, {})
    if (statusCode === 404) {
      throw new NotFoundException()
    }
    if (statusCode !== 200) {
      throw new ServerErrorException()
    }
    return body as Shop
  }
}
