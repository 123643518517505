import  { Action } from 'redux'
import {Shop} from "../../type"
import IAppState from "../states/IAppState"

/**
 * アクション識別ラベル.
 */
export const SET_TITLE = 'SET_TITLE'

export interface ISetTitle extends Action {
  title: string
}
export const createSetTitleAction = (title: string): ISetTitle => {
  return { type: SET_TITLE, title }
}
