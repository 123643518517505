import React from 'react'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import './App.scoped.scss'
import TopPage from "./pages/Top"
import ShopTopPage from "./pages/shops/Top"
import ShopItemListPage from "./pages/shops/ItemList"
import ShopItemDetailPage from "./pages/shops/ItemDetail"
import ShopOrderPage from "./pages/shops/Order"
import NotFoundPage from "./pages/NotFound"
import ErrorPage from "./pages/Error"

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path='/'>
            <TopPage/>
          </Route>
          <Route exact path='/shops/:key'>
            <ShopTopPage/>
          </Route>
          <Route exact path='/shops/:key/items'>
            <ShopItemListPage/>
          </Route>
          <Route exact path='/shops/:key/items/:itemKey'>
            <ShopItemDetailPage/>
          </Route>
          <Route exact path='/shops/:key/order'>
            <ShopOrderPage/>
          </Route>
          <Route exact path='/error'>
            <ErrorPage/>
          </Route>
          <Route path='*'>
            <NotFoundPage/>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
