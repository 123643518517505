import React from 'react';

function Loading() {
  return (
    <div style={{ marginTop: 200, fontSize: 24, textAlign: 'center' }}>
      Hello, we are High Five Salad!<br/>
      <br/>
      No Loading...
    </div>
  );
}

export default Loading;
