import React from 'react';

function TopPage() {
  return (
    <div style={{ marginTop: 200, fontSize: 24, textAlign: 'center' }}>
      Hello, we are High Five Salad!
    </div>
  );
}

export default TopPage;
