import {Reducer, Action} from "redux"
import {
  ISetShop,
  SET_SHOP,
} from "../actions/ShopAction"
import clone from "clone"
import IShopState from "../states/IShopState"
import IAppState from "../states/IAppState"
import {ISetTitle, SET_TITLE} from "../actions/AppAction"

/**
 * Stateの初期値.
 */
const initState: IAppState = {
  title: 'High Five Salad'
}

/**
 *  Reducer
 */
const appReducer: Reducer<IAppState> = (state: IAppState = initState, action: Action) => {
  let newState = clone(state)
  switch (action.type) {
    case SET_TITLE:
      newState.title = (action as ISetTitle).title
      break
    default:
      break
  }
  return newState
}
export default appReducer
