import React from "react"
import './CartFooter.scoped.scss'
import {Order, OrderItem} from "../../../type"
import ItemUtils from "../../../utils/ItemUtils"

interface Props {
  order: Order
  goCart: () => void
}

/**
 * カートのフッターコンポーネント.
 */
const CartFooter: React.FC<Props> = ({ order, goCart }) => {

  const getTotalPrice = (): number => {
    return order.orderItems.reduce((total: number, orderItem: OrderItem) => {
      return total + ItemUtils.getOrderItemPrice(orderItem)
    }, 0)
  }

  const buttonEnabled = () => {
    return getTotalPrice() > 0
  }

  return (
    <div className="footer">
      <div className="flex-grow-1">
        <div className="orderItems">
          {
            order.orderItems.map((orderItem: OrderItem, i: number) => (
              <img src={orderItem.item.imageUrl} key={i}/>
            ))
          }
        </div>
        <div>金額　¥{getTotalPrice().toLocaleString()}</div>
      </div>
      <div className="footerButton">
        <button className={`c-btn ${!buttonEnabled() && 'disabled'}`} onClick={goCart}>
          <span className="material-icons">shopping_cart</span>
          <span>カートを見る</span>
        </button>
      </div>
    </div>
  )
}
export default CartFooter
