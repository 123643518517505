import React from "react"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import './InfoDialog.scoped.scss'
import {Item} from "../../../type"

interface Props {
  item?: Item | null
  onClose?: () => void
}

/**
 * アプリケーションヘッダー.
 */
const InfoDialog: React.FC<Props> = ({ item, onClose }) => {

  if (!item) return null

  return (
    <div className="root">
      <div className="content">

        {/*閉じるボタン*/}
        <div className="close" onClick={() => onClose && onClose()}>
          <span className="closeIcon material-icons">close</span>
        </div>

        {/*商品画像*/}
        <img src={item.imageUrl} className="image"/>

        {/*商品名*/}
        <div className="name">{ item.name }</div>

        {/*商品説明*/}
        <div className="description">{ item.description }</div>

      </div>
    </div>
  )
}
export default InfoDialog
