import {
  Category,
  Item,
  ItemOption,
  ItemOptionGroup,
  ItemSku,
  Order,
  OrderItem,
  OrderItemOption,
  OrderType
} from "../type"
import {
  ITEM_OPTION_GROUP_ACTION_TYPE_FIRST_FREE,
  ITEM_OPTION_GROUP_ACTION_TYPE_TWO_FREE,
  ORDER_TYPE_EAT_IN,
  ORDER_TYPE_EAT_IN_TEXT,
  ORDER_TYPE_TAKE_OUT_TEXT
} from "../AppConst"

/**
 * 商品関連の便利機能.
 */
export default class ItemUtils {

  static items2Categories(items: Item[]): Category[] {

    const categoryIds = new Set<number>()
    const categories: Category[] = []

    items.forEach(item => {
      console.log('category:', item.category)
      // すでに登録済みはスキップ.
      if (item.category && categoryIds.has(item.category.id)) {
        return
      }
      // 並び順指定があるもののみを対象とする.
      if (item.category && item.category.displayOrder != null) {
        categoryIds.add(item.categoryId)
        categories.push(item.category)
      }
    })

    // 並び順でソート.
    return categories.sort((c1, c2) => {
      return c1.displayOrder - c2.displayOrder
    })
  }

  static getItemPriceTextWithSku(item: Item, separator = '　'): string {
    // SKUがない場合はエラー.
    if (!item.skus || item.skus.length === 0) {
      return ''
    }
    // SKUが1つの場合.
    if (item.skus.length === 1) {
      return '¥' + item.skus[0].price.toLocaleString()
    }
    // SKUが2つ以上の場合、SKU名と共に表示.
    const names = item.skus.map(sku => {
      return `${sku.name}:¥${sku.price.toLocaleString()}`
    })
    return names.join(separator)
  }

  static getFirstSkuPriceText(item: Item): string {
    // SKUがない場合はエラー.
    if (!item.skus || item.skus.length === 0) {
      return ''
    }
    return '¥' + item.skus[0].price.toLocaleString()
  }

  // 注文アイテムの指定されたオプションの選択数を返します.
  static getOrderItemOption(orderItem: OrderItem, itemOption: ItemOption, sku: ItemSku): OrderItemOption | null {
    if (!orderItem || !orderItem.orderItemOptions || !itemOption || !sku) {
      return null
    }
    const [ tmp ] = orderItem.orderItemOptions.filter(
      orderItemOption => orderItemOption.itemOption.id === itemOption.id && orderItemOption.skuId === sku.id
    )
    return tmp || null
  }

  // 注文アイテムの指定されたオプションの選択数を返します.
  static getOrderItemOptionCount(orderItem: OrderItem, itemOption: ItemOption, sku: ItemSku): number {
    const orderItemOption = ItemUtils.getOrderItemOption(orderItem, itemOption, sku)
    return orderItemOption ? orderItemOption.amount : 0
  }

  /**
   * 注文アイテムオプションの単価.
   */
  static getOrderItemOptionUnitPrice(orderItemOption: OrderItemOption): number {
    if (orderItemOption.itemOption?.item?.skus?.length > 0) {
      return orderItemOption.itemOption.item.skus[0].price
    }
    return 0
  }

  /**
   * 注文アイテムオプションの金額.
   */
  static getOrderItemOptionTotalPrice(orderItemOption: OrderItemOption): number {
    const price = ItemUtils.getOrderItemOptionUnitPrice(orderItemOption)
    const amount = Math.max(0, orderItemOption.amount - orderItemOption.freeAmount)
    return price * amount
  }

  /**
   * 無料オプション設定の最新化.
   * オプション削除時など、無料アイテムの付け替えが必要な場合があるので、最新化します.
   */
  static refreshFreeOptions(currentOrderItem: OrderItem) {
    currentOrderItem.item.optionGroups.forEach((optionGroup: ItemOptionGroup) => {
      // グループ内無料がある場合のみ対応.
      if (
        optionGroup.actionType !== ITEM_OPTION_GROUP_ACTION_TYPE_FIRST_FREE
        && optionGroup.actionType !== ITEM_OPTION_GROUP_ACTION_TYPE_TWO_FREE
      ) return

      // 残無料数を計算.
      let restFreeAmount = 0
      if (optionGroup.actionType === ITEM_OPTION_GROUP_ACTION_TYPE_FIRST_FREE) restFreeAmount = 1
      if (optionGroup.actionType === ITEM_OPTION_GROUP_ACTION_TYPE_TWO_FREE) restFreeAmount = 2

      // 利用済の無料数を減算.
      currentOrderItem.orderItemOptions.forEach((orderItemOption: OrderItemOption) => {
        if (orderItemOption.itemOption.itemOptionGroupId !== optionGroup.id) return
        restFreeAmount -= orderItemOption.freeAmount
      })

      // 残無料数を配分.
      currentOrderItem.orderItemOptions.forEach((orderItemOption: OrderItemOption) => {
        if (orderItemOption.itemOption.itemOptionGroupId !== optionGroup.id) return
        if (restFreeAmount <= 0) return
        if (orderItemOption.amount <= orderItemOption.freeAmount) return
        restFreeAmount += orderItemOption.freeAmount
        orderItemOption.freeAmount = Math.min(restFreeAmount, orderItemOption.amount)
        restFreeAmount -= orderItemOption.freeAmount
      })
    })

    return currentOrderItem
  }

  // 注文アイテムの金額（orderItem 1つ分の金額）.
  static getOrderItemPrice(orderItem: OrderItem): number {
    if (!orderItem) {
      return 0
    }
    // 注文確定後で、すでに金額計算ができている場合はそれを返却します.
    if (orderItem.price) {
      return orderItem.price
    }

    const itemPrice = (orderItem.sku?.price || 0)
    const optionPrice = (orderItem.orderItemOptions || []).reduce((optionTotal, orderItemOption: OrderItemOption) => {
      return optionTotal + ItemUtils.getOrderItemOptionTotalPrice(orderItemOption)
    }, 0)

    return itemPrice + optionPrice
  }

  /**
   * 消費税率を取得します.
   * @param item 商品
   * @param isEatIn イートインの場合、true
   * @return 消費税率（10%の場合は0.1、8%の場合は0.08、といった具合）
   */
  static getTaxRatio(item: Item, isEatIn: boolean): number {
    return (isEatIn ? item.taxEatIn : item.taxTakeOut) / 100
  }

  /**
   * 消費税を計算します（内税）.
   * ここでは少数以下も含めて返却します.
   * @param priceWithTax 税込価格
   * @param taxRatio 消費税率（10%の場合0.1、8%の場合0.08）
   * @return 消費税額（小数を含む）
   */
  static calcTax(priceWithTax: number, taxRatio: number): number {
    // 内税計算：消費税額 ＝ 税込価格 ÷（1 + 消費税率）× 消費税率
    return priceWithTax / (1 + taxRatio) * taxRatio
  }

  /**
   * 総額を計算し、オーダーオブジェクトに設定します.
   */
  static populateOrderTotalPrice(order: Order) {
    if (!order) {
      return order
    }
    const isEatIn = order.orderType === ORDER_TYPE_EAT_IN
    let total = 0
    let tax = 0
    order.orderItems.forEach((orderItem: OrderItem) => {
      let itemPrice = orderItem.sku?.price || 0
      let itemTax = ItemUtils.calcTax(itemPrice, ItemUtils.getTaxRatio(orderItem.item, isEatIn))
      console.log('itemTax:', orderItem.item.id, orderItem.item.name, itemTax, ItemUtils.getTaxRatio(orderItem.item, isEatIn))
      let optionsPrice = 0
      let optionsTax = 0
      orderItem.orderItemOptions.forEach((orderItemOption: OrderItemOption) => {
        // マスタデータの金額を、注文データにも反映.
        orderItemOption.price = ItemUtils.getOrderItemOptionUnitPrice(orderItemOption)
        optionsPrice += ItemUtils.getOrderItemOptionTotalPrice(orderItemOption)
        const optionTax = ItemUtils.calcTax(
          ItemUtils.getOrderItemOptionTotalPrice(orderItemOption),
          ItemUtils.getTaxRatio(orderItemOption.itemOption.item, isEatIn)
        )
        console.log('optionTax:', orderItemOption.itemOption.item.name, optionTax)
        optionsTax += optionTax
      })
      // マスタデータの金額を、注文データにも反映.
      orderItem.price = itemPrice + optionsPrice
      // 合計金額に追加.
      total += orderItem.price * orderItem.amount
      // 消費税額に追加.
      tax += (itemTax + optionsTax) * orderItem.amount
    })

    // クーポンの反映.
    order.priceBeforeDiscount = total
    // Web版ではクーポン利用はないため、コメントアウト.
    // if (order.coupon) {
      // const coupon = order.coupon
      // // 最も高額な商品１つを対象にする.
      // let reduceTax = 0
      // const price = order.orderItems.reduce((prevPrice, item) => {
      //   if (item.sku && item.sku.price > prevPrice) {
      //     reduceTax = ItemUtils.calcTax(item.sku.price, ItemUtils.getTaxRatio(item.item, isEatIn)) * coupon.discountRate
      //   }
      //   return Math.max(prevPrice, item.sku?.price || 0)
      // }, 0)
      // const discount = Math.floor(price * coupon.discountRate);
      // total -= discount
      // tax -= reduceTax
      // console.log(`reduceTax: ${reduceTax}`)
    // }

    // 総額の反映.
    order.price = total

    // 消費税の反映.
    console.log('tax1:', tax)
    tax = Math.floor(tax)
    console.log('tax2:', tax)
    order.priceWithoutTax = total - tax
    order.tax = tax
    if (isEatIn) {
      order.price_for_tax10 = order.priceWithoutTax
      order.price_for_tax8 = 0
    } else {
      order.price_for_tax10 = 0
      order.price_for_tax8 = order.priceWithoutTax
    }
    return order
  }

  // 消費税の計算.
  // 計算式（端数切り捨て）
  // 　・消費税（円）=［税込み価格］（円）÷（１+消費税率）×（消費税率）
  static getIncludedTax(total: number, taxRatio: number) {
    return Math.floor(total / (1 + taxRatio) * taxRatio)
  }

  // オーダータイプのテキスト.
  static getOrderTypeText(orderType: OrderType): string {
    if (orderType === ORDER_TYPE_EAT_IN) {
      return ORDER_TYPE_EAT_IN_TEXT
    }
    return ORDER_TYPE_TAKE_OUT_TEXT
  }

  // 乱数を作成.
  static createRandomString(len: number = 16): string {
    const seed = '1234567890abcdefghijklmnopqrstuvwxyzABDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    const seedSize = seed.length
    const arr: string[] = []
    for (let i = 0; i < len; i++) {
      arr.push(seed[Math.floor(Math.random() * seedSize)])
    }
    return arr.join('')
  }
}
