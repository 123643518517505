import React from 'react';
import AppHeader from "../components/organisms/headers/AppHeader"

function NotFoundPage() {
  return (
    <>
      <AppHeader/>
      <div style={{ marginTop: 100, fontSize: 16, textAlign: 'center', lineHeight: 2 }}>
        申し訳ありません、ページが見つかりません。
      </div>
    </>
  );
}

export default NotFoundPage;
