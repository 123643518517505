import React from "react"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import './AppHeader.scoped.scss'

interface Props {
  title?: string
  leftVisible?: boolean
  leftCallback?: () => void
}

/**
 * アプリケーションヘッダー.
 */
const AppHeader: React.FC<Props> = ({ title, leftVisible, leftCallback }) => {

  return (
    <header className='header'>
      {
        leftVisible && (
          <span className="left" onClick={ () => leftCallback && leftCallback() }>
            <ArrowBackIosIcon/>
          </span>
        )
      }
      <span>{ title || 'High Five Salad' }</span>
    </header>
  )
}
export default AppHeader
