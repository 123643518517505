export let URL_AllERGIES_PDF = 'https://app.highfive.tokyo/file/allergies.pdf'
export let URL_SPECIAL_COMMERCE_LAW = 'https://app.highfive.tokyo/terms/special-commerce-law'
export let URL_PRIVACY = 'https://app.highfive.tokyo/terms/privacy'
export let URL_TERM_OF_USE = 'https://app.highfive.tokyo/terms/term-of-use'
export let URL_CONTACT_TEL = 'tel://05037078954'
export let URL_CONTACT_MAIL = 'mailto:order@highfive.tokyo'

export const COLOR_DARK = '#323232'
export const COLOR_WHITE = '#FFFFFF'
export const COLOR_WHITE_0_96 = 'rgba(255, 255, 255, 0.96)'
export const COLOR_WHITE_0_64 = 'rgba(255, 255, 255, 0.64)'
export const COLOR_BLACK_0_08 = 'rgba(0, 0, 0, 0.08)'
export const COLOR_BLACK_0_16 = 'rgba(0, 0, 0, 0.16)'
export const COLOR_BLACK_0_24 = 'rgba(0, 0, 0, 0.24)'
export const COLOR_BLACK_0_64 = 'rgba(0, 0, 0, 0.64)'
export const COLOR_BLUE = '#113366'
export const COLOR_ERROR = '#FF0000'
export const COLOR_BEAUTY_PINK = '#FF9199'
export const COLOR_ANTIAGING_GREEN = '#8FD93A'
export const COLOR_RECOVERY_BLUE = '#3FB5E0'
export const COLOR_MUSCLE_ORANGE = '#FF9B14'
export const COLOR_IMMUNE_PURPLE = '#CB88CC'
export const COLOR_BLACK = '#000000'

export const ORDER_STATUS_NOT_YET = 'notYet'
export const ORDER_STATUS_COOKING = 'cooking'
export const ORDER_STATUS_COOKED = 'cooked'
export const ORDER_STATUS_DONE = 'done'

export const ORDER_TYPE_EAT_IN = 'eatIn'
export const ORDER_TYPE_TAKE_OUT = 'takeOut'
export const ORDER_TYPE_EAT_IN_TEXT = 'イートイン'
export const ORDER_TYPE_TAKE_OUT_TEXT = 'テイクアウト'

export const PICKUP_DATETIME_FORMAT = 'yyyy-MM-DD HH:mm:ss'
export const YYYYMMDDHHMM_DATE_FORMAT_DISPLAY = 'yyyy年MM月DD日 HH時mm分'
export const YYYYMMDD_SLASH = 'yyyy/MM/DD'
export const YYYYMMDDHHMM_SLASH = 'yyyy/MM/DD HH:mm'
export const PICKUP_DATE_FORMAT_DISPLAY = 'M月D日'
export const PICKUP_TIME_FORMAT_DISPLAY = 'H:mm以降'
export const PICKUP_DATETIME_FORMAT_DISPLAY = 'M月D日 H:mm以降'

// オプショングループの動作タイプ.
export const ITEM_OPTION_GROUP_ACTION_TYPE_NORMAL = 'normal'        // 通常
export const ITEM_OPTION_GROUP_ACTION_TYPE_FIRST_FREE = 'firstFree' // グループ内1つ目無料
export const ITEM_OPTION_GROUP_ACTION_TYPE_TWO_FREE = 'twoFree' // グループ内2つまで無料
export const ITEM_OPTION_GROUP_ACTION_TYPE_ONLY_ONE = 'onlyOne'     // グループ内1つのみ選択可能.
export const ITEM_OPTION_GROUP_ACTION_TYPE_FIXED = 'fixed'     // 固定（スポットでドレッシング固定など）.

// 曜日.
export const DAY_OF_WEEK_TEXTS = [ '日', '月', '火', '水', '木', '金', '土' ]

// アイテム機能性表示.
export const ITEM_FUNCTIONALITY_BEAUTY = 'beauty'
export const ITEM_FUNCTIONALITY_ANTIAGING = 'antiaging'
export const ITEM_FUNCTIONALITY_RECOVER = 'recover'
export const ITEM_FUNCTIONALITY_MUSCLE = 'muscle'
export const ITEM_FUNCTIONALITY_IMMUNE = 'immune'
export const ITEM_FUNCTIONALITY_COUTION = 'coution'
export const ITEM_FUNCTIONALITY = {
  [ITEM_FUNCTIONALITY_BEAUTY] : 'Beauty',
  [ITEM_FUNCTIONALITY_ANTIAGING] : 'Anti Aging',
  [ITEM_FUNCTIONALITY_RECOVER] : 'Recover',
  [ITEM_FUNCTIONALITY_MUSCLE] : 'Muscle',
  [ITEM_FUNCTIONALITY_IMMUNE] : 'Immune',
  [ITEM_FUNCTIONALITY_COUTION] : 'Coution',
}

// 注文ID.
export const SESSION_STORAGE_KEY_ORDER_ID = 'order-id'
// 注文No.
export const SESSION_STORAGE_KEY_ORDER_NO = 'order-no'

// 商品ID固定.
export const ITEM_KEY_BAG = 'bag'
