import React from 'react';
import AppHeader from "../components/organisms/headers/AppHeader"

function ErrorPage() {
  return (
    <>
      <AppHeader/>
      <div style={{ marginTop: 100, fontSize: 16, textAlign: 'center', lineHeight: 2 }}>
        申し訳ありません、エラーが発生しました。<br/>しばらくお待ちいただき再度アクセスください。
      </div>
    </>
  );
}

export default ErrorPage;
