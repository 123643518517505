import {Reducer, Action} from "redux"
import {
  ISetItems,
  ISetShop, SET_ITEMS,
  SET_SHOP,
} from "../actions/ShopAction"
import clone from "clone"
import IShopState from "../states/IShopState"

/**
 * Stateの初期値.
 */
const initState: IShopState = {
  shop: null,
  items: null
}

/**
 *  Reducer
 */
const shopReducer: Reducer<IShopState> = (state: IShopState = initState, action: Action) => {
  let newState = clone(state)
  switch (action.type) {
    // 店舗情報を保存.
    case SET_SHOP:
      newState.shop = (action as ISetShop).shop
      break
    // 商品情報を保存.
    case SET_ITEMS:
      newState.items = (action as ISetItems).items
      break
    default:
      break
  }
  return newState
}
export default shopReducer
