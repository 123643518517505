import React, {useEffect, useState} from "react"
import AppHeader from "../../components/organisms/headers/AppHeader"
import {useHistory, useParams} from "react-router-dom"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import {useAppDispatch, useAppSelector} from "../../redux/hooks"
import {RootState} from "../../redux/Store"
import {createSetItemsAction} from "../../redux/actions/ShopAction"
import ItemApi from "../../apis/ItemApi"
import {createCategories, getItemPriceTextWithSku} from "../../services/ItemService"
import {Category, Item} from "../../type"
import './ItemList.scoped.scss'
import Functionality from "../../components/atoms/Functionality/Functionality"
import CartFooter from "../../components/organisms/footers/CartFooter"
import {createCreateOrderItemAction} from "../../redux/actions/OrderAction"
import InfoDialog from "../../components/organisms/infoDialog/InfoDialog"

interface ParamTypes {
  key: string
}

const DEFAULT_CATEGORY_ID = -1

// TODO partialVisibilityGutter は細かめに調整が必要かな〜..

const responsive = {
  tablet: {
    breakpoint: { max: 9999, min: 464 },
    items: 2,
    partialVisibilityGutter: 120
  },
  mobile: {
    breakpoint: { max: 640, min: 350 },
    items: 1,
    partialVisibilityGutter: 40
  },
  smallMobile: {
    breakpoint: { max: 350, min: 0 },
    items: 1,
    partialVisibilityGutter: 20
  }
}

/**
 * 店舗トップ.
 */
export default function ShopItemListPage() {
  const history = useHistory()
  const dispatch  = useAppDispatch()
  const { key } = useParams<ParamTypes>();
  // 店舗情報を取得.
  const shop = useAppSelector((state: RootState) => state.shop.shop)
  // 商品情報を取得.
  const items = useAppSelector((state: RootState) => state.shop.items)
  // 注文情報を取得.
  const order = useAppSelector((state: RootState) => state.order.order)
  // 選択中のカテゴリID.
  const [ selectedCategoryId, setSelectedCategoryId ] = useState<number>(DEFAULT_CATEGORY_ID)
  // 説明対象の商品.
  const [ infoItem, setInfoItem ] = useState<Item | null>(null)

  // 初期表示時に、商品一覧を取得.
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const items = await (new ItemApi()).list(key)
        dispatch(createSetItemsAction(items))
      } catch (e) {
        // サーバーエラーページへ
        history.push('/error')
      }

    }
    fetchItems()
  }, [key]);

  // 店舗情報がない場合は、店舗詳細へ戻る.
  if (!shop || !order) {
    history.push(`/shops/${key}`)
    return null
  }

  // カテゴリー一覧.
  const categories = createCategories(items)

  // 未選択の場合は最初の1つ目を選択する.
  if (selectedCategoryId === DEFAULT_CATEGORY_ID && categories.length > 0) {
    setSelectedCategoryId(categories[0].id)
  }

  // カテゴリーをクリックした.
  const onClickCategory = (category: Category) => {
    console.log('onClickCategory:', category)
    setSelectedCategoryId(category.id)
  }

  // 商品を選択したら、現在の注文に追加し、オプション選択へ移動.
  const onClickItem = (item: Item) => {

    // 在庫切れの場合は、クリックさせない.
    if (item.outOfStock) return

    // 商品詳細ページへ.
    dispatch(createCreateOrderItemAction(item))
    history.push(`/shops/${key}/items/${item.key}`)
  }

  // カート画面へ.
  const onClickGoCart = () => {
    history.push(`/shops/${key}/order`)
    console.log('onClickGoCart')
  }

  // 読み込み中の場合.
  if (!items) {
    return (
      <>
        <AppHeader
          title={shop?.name}
          leftVisible={true}
          leftCallback={ () => history.goBack() }/>
        <div className="c-container">
          <div className="text-center">Loading...</div>
        </div>
      </>
    )
  }

  // 表示する商品一覧（カテゴリーが一致するもののみ）.
  const itemsForDisplay = items.filter(item => item.categoryId === selectedCategoryId)

  return (
    <>
      <AppHeader
        title={shop?.name}
        leftVisible={true}
        leftCallback={ () => history.goBack() }/>
      <div className="c-container is-no-padding is-bg-gray">
        <div className="c-plr-16 c-bg-white">
          <div className="title">商品をお選びください</div>
          {/*カテゴリー一覧*/}
          <div className="categories">
            <div className="categoriesInner">
              { categories.map((category, index) => (
                <div className={`category ${selectedCategoryId === category.id && 'selected'}`} onClick={onClickCategory.bind(null, category)}>
                {category.name}
              </div>
              ))}
            </div>
          </div>
        </div>
        {/*商品一覧*/}
        <div className="items">
          <div className="itemsInner">
            {itemsForDisplay.map((item: Item) => (
              <div className="item">
                {/*商品説明のアイコン*/}
                { !!item.description && (
                  <div className="itemIcon" onClick={() => setInfoItem(item)}>
                    <span className="material-icons">info_outline</span>
                  </div>
                )}
                <div onClick={onClickItem.bind(null, item)} key={item.key}>
                  <div className="itemImageContainer">
                    <img src={item.imageUrl} className={`itemImage ${item.outOfStock && 'opacity30' }`} />
                    {item.outOfStock && (
                      <div className="outOfStockText">ただいまご注文いただけません</div>
                    )}
                  </div>
                  <div className="itemName">{item.name}</div>
                  <div className="itemPrice">{getItemPriceTextWithSku(item)}</div>
                  {item.functionality && (
                    <div className="text-center">
                      <Functionality type={item.functionality}/>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*カートフッター*/}
        <CartFooter order={order} goCart={onClickGoCart}/>

        {/*商品説明ダイアログ*/}
        <InfoDialog
          item={infoItem}
          onClose={ () => setInfoItem(null) }/>
      </div>
    </>
  )
}
