import React from "react"
import './ItemOptionFooter.scoped.scss'
import {ORDER_TYPE_EAT_IN} from "../../../AppConst"
import {OrderItem} from "../../../type"
import ItemUtils from "../../../utils/ItemUtils"

interface Props {
  currentOrderItem: OrderItem
  nextText: string
  onNext: () => void
  backToTopping: (() => void) | null
}

/**
 * カートのフッターコンポーネント.
 */
const ItemOptionFooter: React.FC<Props> = ({ currentOrderItem, onNext, nextText = '次へ', backToTopping }) => {

  return (
    <div className="footer">
      <div className="flex-grow-1">
        <div className="name">{ currentOrderItem.item.name }</div>
        <div className="price">金額 ¥{ ItemUtils.getOrderItemPrice(currentOrderItem) }</div>
        {
          !backToTopping ? (
            <>
              <div className="note">
                <span>{currentOrderItem.sku?.cal || 0}kcal</span>
                {/*TODO 糖質12.0g の表示？*/}
              </div>
              <div className="note">
                <span>P:{ currentOrderItem.sku?.protein || 0}g</span>
                <span className="pl-2">F:{ currentOrderItem.sku?.fat || 0}g</span>
                <span className="pl-2">C:{ currentOrderItem.sku?.carbon || 0}g</span>
              </div>
              <a className="link" href={process.env.REACT_APP_API_ROOT + '/files/allergies.pdf'} target="_blank">アレルギー一覧をご確認ください</a>
            </>
          ) : (
            <button
              className={`c-btn is-outline btn-back`}
              onClick={backToTopping.bind(null, true)}>
              トッピングを選択しなおす
            </button>
          )
        }
      </div>
      <div className="footerButton">
        <button className="c-btn" onClick={onNext}>
          <span className="pr-1">{nextText}</span>
          <span className="material-icons">keyboard_arrow_right</span>
        </button>
      </div>
    </div>
  )
}

export default ItemOptionFooter
