import AbstractApi from "./AbstractApi"
import ServerErrorException from "../exceptions/ServerErrorException"
import {GetWebOrderResponse, Order, Shop, UpdateWebOrderRequest} from "../type"
import NotFoundException from "../exceptions/NotFoundException"

export default class OrderApi extends AbstractApi {

  async get(key: string): Promise<GetWebOrderResponse> {
    const { statusCode, body } = await this._get(`/api/web/web-orders/${key}`, {})
    if (statusCode !== 200) {
      throw new ServerErrorException()
    }
    return body as GetWebOrderResponse
  }

  async add(order: Order) {
    let data = order as any
    return this._post('/api/web/web-orders', data)
  }

  async update(key: string, data: UpdateWebOrderRequest) {
    return this._put(`/api/web/web-orders/${key}`, data)
  }
}
