/**
 * 店舗トップ.
 */
import React, {useEffect, useState} from "react"
import './ItemDetail.scoped.scss'
import {useHistory, useParams} from "react-router-dom"
import {useAppDispatch, useAppSelector} from "../../redux/hooks"
import {RootState} from "../../redux/Store"
import {Item, ItemOption, ItemOptionGroup, ItemSku} from "../../type"
import {
  createAddCartAction,
  createAddOrderItemOptionAction,
  createDeleteCurrentOrderItemAction,
  createDeleteOrderItemOptionAction,
  createUpdateDressingMixedAction,
  createUpdateOrderItemSkuAction,
  createUpdateSoupWarmedAction
} from "../../redux/actions/OrderAction"
import {
  ITEM_OPTION_GROUP_ACTION_TYPE_FIRST_FREE,
  ITEM_OPTION_GROUP_ACTION_TYPE_ONLY_ONE,
  ITEM_OPTION_GROUP_ACTION_TYPE_TWO_FREE,
  ORDER_TYPE_EAT_IN,
  ORDER_TYPE_TAKE_OUT,
} from "../../AppConst"
import ItemOptionFooter from "../../components/organisms/footers/ItemOptionFooter"
import ItemUtils from "../../utils/ItemUtils"
import InfoDialog from "../../components/organisms/infoDialog/InfoDialog"

interface ParamTypes {
  key: string
  itemKey: string
}

export default function ShopItemDetailPage() {
  const history = useHistory()
  const dispatch  = useAppDispatch()
  const { key, itemKey } = useParams<ParamTypes>();
  // 店舗情報を取得.
  const shop = useAppSelector((state: RootState) => state.shop.shop)
  // 商品情報を取得.
  const items = useAppSelector((state: RootState) => state.shop.items)
  // 注文情報を取得.
  const order = useAppSelector((state: RootState) => state.order.order)
  // カスタマイズ中の商品を取得.
  const currentItem = useAppSelector((state: RootState) => state.order.currentOrderItem)
  // ページ.
  const [ page, setPage ] = useState<number>(1);
  // windowの高さ.
  const optionViewHeight = window.innerHeight
  // 説明対象の商品.
  const [ infoItem, setInfoItem ] = useState<Item | null>(null)

  // 初期表示時.
  useEffect(() => {
    // イートインの場合は、「ドレッシング和える」をデフォルト選択.
    if (order?.orderType === ORDER_TYPE_EAT_IN) {
      dispatch(createUpdateDressingMixedAction(true))
    }
    // 1つ目のSKUをデフォルト表示.
    if (currentItem && currentItem.item.skus.length > 0) {
      dispatch(createUpdateOrderItemSkuAction(currentItem.item.skus[0]))
    }
  }, [key]);

  // 情報がない場合は、不正遷移のため店舗詳細へ戻る.
  if (!shop || !items || !order || !currentItem) {
    history.push(`/shops/${key}`)
    return null
  }

  // 閉じるボタンを押された場合、選択中の商品を破棄して、商品一覧へ戻る.
  const onClickClose = () => {
    dispatch(createDeleteCurrentOrderItemAction())
    history.push(`/shops/${key}/items`)
  }

  // SKU変更が押されたとき.
  const onClickSku = (sku: ItemSku) => {
    dispatch(createUpdateOrderItemSkuAction(sku))
  }

  // ドレッシング和える選択が押されたとき.
  const onClickDressingMixed = (mixed: boolean) => {
    dispatch(createUpdateDressingMixedAction(mixed))
  }

  // スープを温める選択が押されたとき.
  const onClickSoupWarmed = (warmed: boolean) => {
    dispatch(createUpdateSoupWarmedAction(warmed))
  }

  // オプションを追加.
  const onClickAddOption = (optionGroup: ItemOptionGroup, itemOption: ItemOption) => {
    // 品切れの場合は無効.
    if (itemOption.item.outOfStock) return
    dispatch(createAddOrderItemOptionAction(optionGroup, itemOption, undefined))
  }

  // オプションを削除.
  const onClickDeleteOption = (itemOption: ItemOption) => {
    // 品切れの場合は無効.
    if (itemOption.item.outOfStock) return
    dispatch(createDeleteOrderItemOptionAction(itemOption))
  }

  // カートへ追加が押された.
  const onClickAddCart = () => {
    if (hasNextPage()) {
      setPage(page + 1)
      return
    }
    dispatch(createAddCartAction())
    // 商品一覧へ戻る.
    history.push(`/shops/${key}/items`)
  }

  // 次のページがあるか.
  const hasNextPage = (): boolean => {
    return currentItem.item.optionGroups.filter(optionGroup => {
      return optionGroup.page > page
    }).length > 0
  }

  // トッピング選択へ戻る.
  const goBackTopping = () => {
    setPage(1)
    window.scrollTo(0, 0)
  }

  // 選択されたオプション数を返却します.
  const getOptionCount = (itemOption: ItemOption, sku: ItemSku): number => {
    return ItemUtils.getOrderItemOptionCount(currentItem, itemOption, sku)
  }

  // 説明アイコンを表示.
  const renderInfoIcon = (item: Item) => {
    if (!item || !item.description) return null
    return (
      <div className="itemIcon" onClick={ () => setInfoItem(item) }>
        <span className="material-icons">info_outline</span>
      </div>
    )
  }

  // オプションの金額をレンダリングします.
  const renderPrice = (itemOption: ItemOption, sku: ItemSku) => {
    const orderItemOption = ItemUtils.getOrderItemOption(currentItem, itemOption, sku)
    if (!orderItemOption || orderItemOption.freeAmount <= 0 || orderItemOption.amount <= 0) {
      return (
        <div className="optionPrice">
          { renderInfoIcon(itemOption.item) }
          { ItemUtils.getFirstSkuPriceText(itemOption.item) }
        </div>
      )
    }
    if (orderItemOption.amount <= orderItemOption.freeAmount) {
      return (
        <div className="optionPrice">
          { renderInfoIcon(itemOption.item) }
          <span className="optionPriceLineThrough pr-1">{ ItemUtils.getFirstSkuPriceText(itemOption.item) }</span>
          <span>¥0</span>
        </div>
      )
    }
    return (
      <div className="optionPrice">
        { renderInfoIcon(itemOption.item) }
        <span>{ ItemUtils.getFirstSkuPriceText(itemOption.item) }</span>
        <span className="pl-1 pr-1">&</span>
        <span>FREE</span>
      </div>
    )
  }

  // オプショングループの注釈を返却します.
  const renderOptionGroupNote = (itemOptionGroup: ItemOptionGroup) => {
    switch (itemOptionGroup.actionType) {
      case ITEM_OPTION_GROUP_ACTION_TYPE_ONLY_ONE: return '１つのみ選択可能です。'
      case ITEM_OPTION_GROUP_ACTION_TYPE_FIRST_FREE: return '１つ目を無料でお選びいただけます。'
      case ITEM_OPTION_GROUP_ACTION_TYPE_TWO_FREE: return '２つまで無料でお選びいただけます。'
    }
    return null
  }


  // 表示するオプショングループ.
  const itemOptionGroups = currentItem.item.optionGroups.filter(optionGroup => {
    return optionGroup.page === page
  })

  // オプション一覧を表示します.
  const renderOptionGroupItems = (optionGroup: ItemOptionGroup) => {
    const snipets = optionGroup.options.map(option => {
      return option.item.skus.map(sku => {
        const isSelected = getOptionCount(option, sku) > 0
        const tmp = {
          ...option,
          item: {
            ...option.item,
            skus: [ sku ]
          }
        }
        return (
          <div className={`optionSelectItem ${isSelected && 'is-selected'}`} key={sku.key}>
            <div className="optionSelectImage" onClick={onClickAddOption.bind(null, optionGroup, tmp)}>
              <img src={option.item.imageUrl} className={`${tmp.item.outOfStock ?? 'opacity30'}`}/>
              {tmp.item.outOfStock && (
                <div className="outOfStockText">品切れ</div>
              )}
            </div>
            <div className="optionSelectItemDelete" onClick={onClickDeleteOption.bind(null, tmp)}>
              <span className="material-icons">cancel</span>
              <span className="optionSelectItemDeleteText ml-1">削除</span>
            </div>
            <div className="optionName">{tmp.item.name}{ sku.name }</div>
            <div className="optionPriceAndInfo">
              { renderPrice(tmp, sku) }
            </div>
            {/*注文数*/}
            { isSelected && (
              <div className="toppingCount">{ getOptionCount(tmp, sku) }</div>
            )}
          </div>
        )
      })
    })
    return snipets.flat()
  }

  // 現在選択中のSKU（サイズ）.
  const selectedSku = currentItem.sku

  return (
    <div className="root">
      {/*閉じるボタン*/}
      <div className="close">
        <span className="material-icons" onClick={onClickClose}>close</span>
      </div>
      {/*商品画像・閉じるボタン*/}
      <div className="thumbnail">
        <img src={currentItem.item.imageUrl}/>
      </div>
      {/*オプション選択View*/}
      <div className="optionView c-container" style={{ minHeight: `${optionViewHeight}px` }}>

        {/*サイズ選択*/}
        {
          !!(page === 1 && currentItem.item.skus && currentItem.item.skus.length > 1) && (
            <>
              <div className="optionSubHeader">サイズ</div>
              <div className="sizes">
                {
                  currentItem.item.skus.map(sku => (
                    <button
                      className={`size mr-2 btn ${selectedSku && selectedSku.id === sku.id ? 'btn-dark' : 'btn-light'}`}
                      key={sku.id}
                      onClick={onClickSku.bind(null, sku)}
                    >{sku.name}</button>
                  ))
                }
              </div>
            </>
          )
        }

        {/*ドレッシング和える選択（1ページ目 & isSalad & テイクアウトのみ）*/}
        {
          !!(page === 1 && currentItem.item.isSalad && order.orderType === ORDER_TYPE_TAKE_OUT) && (
            <>
              <div className="optionSubHeader">ドレッシングを和える</div>
              <div className="optionCaption">和える場合はべースドレッシングで下味をお付けしてご提供します。</div>
              <div className="sizes">
                <button
                  className={`size mr-2 btn btn-sm ${!currentItem.dressingMixed && 'btn-dark' }`}
                  onClick={() => onClickDressingMixed(false)}
                >別添え</button>
                <button
                  className={`size mr-2 btn btn-sm ${currentItem.dressingMixed && 'btn-dark'}`}
                  onClick={() => onClickDressingMixed(true)}
                >和える</button>
              </div>
            </>
          )
        }

        {/*スープを混ぜる選択（1ページ目 & askWarm & テイクアウトのみ）*/}
        {
          !!(page === 1 && currentItem.item.askWarm && order.orderType === ORDER_TYPE_TAKE_OUT) && (
            <>
              <div className="optionSubHeader">スープを温める</div>
              <div className="sizes">
                <button
                  className={`size mr-2 btn btn-sm ${!currentItem.soupWarmed && 'btn-dark' }`}
                  onClick={() => onClickSoupWarmed(false)}
                >スープを温めない</button>
                <button
                  className={`size mr-2 btn btn-sm ${currentItem.soupWarmed && 'btn-dark'}`}
                  onClick={() => onClickSoupWarmed(true)}
                >スープを温める</button>
              </div>
            </>
          )
        }

        { itemOptionGroups.length > 0 && (
          <div className="optionHeader">CUSTOM ORDER</div>
        )}

        {itemOptionGroups.map(optionGroup => (
          <div className="optionGroup" key={optionGroup.key}>
            <div className="optionSubHeader">{optionGroup.name}</div>
            {!!renderOptionGroupNote(optionGroup) && (
              <div className="optionCaption">{ renderOptionGroupNote(optionGroup) }</div>
            )}
            <div className={`optionSelect clearfix ${optionGroup.name.indexOf('ドレッシング') !== -1 ? 'is-dressing' : 'is-3-col'}`}>
              <div
                className="optionSelectInner"
                style={{ width: optionGroup.name.indexOf('ドレッシング') !== -1 ? (optionGroup.options.length * 136) + 'px' : 'auto' }}>
                {renderOptionGroupItems(optionGroup)}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/*オプション選択フッター*/}
      {/*TODO ページ2から、トッピングへ戻る対応.*/}
      <ItemOptionFooter
        currentOrderItem={currentItem}
        nextText={hasNextPage() ? '次へ' : '決定'}
        onNext={onClickAddCart}
        backToTopping={ page === 2 ? goBackTopping : null }/>

      {/*商品説明ダイアログ*/}
      <InfoDialog
        item={infoItem}
        onClose={ () => setInfoItem(null) }/>

    </div>
  )
}
