/**
 * 商品に関するいろいろな処理.
 */
import {Category, Item} from "../type"

// 商品一覧からユニークなカテゴリー一覧を生成します.
export function createCategories(items: Item[] | null): Category[] {

  let categories: Category[] = []
  const usedIds = new Set<number>()

  ;(items || []).forEach((item: Item) => {
    const category = item.category
    if (!category) {
      return
    }
    if (usedIds.has(category.id)) {
      return
    }
    categories.push(category)
    usedIds.add(category.id)
  })

  // 表示順に並び替え.
  categories = categories.sort((c1, c2) => {
    return c1.displayOrder - c2.displayOrder
  })

  return categories
}

// 商品の金額を表示する（SKUごとに表示）.
export function getItemPriceTextWithSku(item: Item, separator = '　'): string {
  // SKUがない場合はエラー.
  if (!item.skus || item.skus.length === 0) {
    return ''
  }
  // SKUが1つの場合.
  if (item.skus.length === 1) {
    return '¥' + item.skus[0].price.toLocaleString()
  }
  // SKUが2つ以上の場合、SKU名と共に表示.
  const names = item.skus.map(sku => {
    return `${sku.name}:¥${sku.price.toLocaleString()}`
  })
  return names.join(separator)
}

// 乱数を作成.
export function createRandomString(len: number = 16): string {
  const seed = '1234567890abcdefghijklmnopqrstuvwxyzABDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
  const seedSize = seed.length
  const arr: string[] = []
  for (let i = 0; i < len; i++) {
    arr.push(seed[Math.floor(Math.random() * seedSize)])
  }
  return arr.join('')
}
