import  { Action } from 'redux'
import {Item, Shop} from "../../type"

/**
 * アクション識別ラベル.
 */
export const SET_SHOP = 'SET_SHOP'
export const SET_ITEMS = 'SET_ITEMS'

export interface ISetShop extends Action {
  shop: Shop
}
export const createSetShopAction = (shop: Shop): ISetShop => {
  return { type: SET_SHOP, shop }
}

export interface ISetItems extends Action {
  items: Item[]
}
export const createSetItemsAction = (items: Item[]): ISetItems => {
  return { type: SET_ITEMS, items }
}
