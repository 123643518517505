import {Action, Reducer} from "redux"
import clone from "clone"
import IOrderState from "../states/IOrderState"
import {
  ADD_CART,
  ADD_ORDER_ITEM_OPTION, CHANGE_ORDER_ITEM, CHANGE_ORDER_ITEM_AMOUNT,
  CREATE_ORDER,
  CREATE_ORDER_ITEM,
  DELETE_CURRENT_ORDER_ITEM, DELETE_ORDER_ITEM,
  DELETE_ORDER_ITEM_OPTION,
  IAddOrderItemOption, IChangeOrderItem, IChangeOrderItemAmount,
  ICreateOrder,
  ICreateOrderItem, IDeleteOrderItem,
  IDeleteOrderItemOption, ISetBagNum,
  IUpdateDressingMixed,
  IUpdateOrderItemSku, IUpdateSoupWarmed, RESET_CART, SET_BAG_NUM,
  UPDATE_DRESSING_MIXED,
  UPDATE_ORDER_ITEM_SKU, UPDATE_SOUP_WARMED
} from "../actions/OrderAction"
import {ITEM_KEY_BAG, ORDER_STATUS_NOT_YET} from "../../AppConst"
import {createRandomString} from "../../services/ItemService"
import OrderUtils from "../../utils/OrderUtils"
import ItemUtils from "../../utils/ItemUtils"

/**
 * Stateの初期値.
 */
const initState: IOrderState = {
  order: null,
  currentOrderItem: null
}

/**
 *  Reducer
 */
const orderReducer: Reducer<IOrderState> = (state: IOrderState = initState, action: Action) => {
  let newState = clone(state)
  switch (action.type) {
    // 注文作成.
    case CREATE_ORDER:
      const { orderType, shop } = (action as ICreateOrder)
      newState.order = {
        id: '',
        key: '',
        orderItems: [],
        pickupAt: '',
        pickupDatetime: null,
        price: 0,
        priceWithoutTax: 0,
        tax: 0,
        price_for_tax8: 0,
        price_for_tax10: 0,
        priceBeforeDiscount: 0,
        coupon: null,
        status: ORDER_STATUS_NOT_YET,
        orderType,
        shop,
      }
      break
    // 注文アイテム作成.
    case CREATE_ORDER_ITEM:
      const { item } = (action as ICreateOrderItem)
      newState.currentOrderItem = {
        orderItemOptions: [],
        price: 0,
        sku: item.skus ? item.skus[0] : null,  // デフォで1つ目のSKUを選択する.
        dressingMixed: false,
        soupWarmed: false,
        key: createRandomString(),
        item,
        amount: 1
      }
      break
    // 現在の注文アイテム削除.
    case DELETE_CURRENT_ORDER_ITEM:
      newState.currentOrderItem = null
      break
    // 注文アイテムのSKU更新.
    case UPDATE_ORDER_ITEM_SKU:
      const { sku } = (action as IUpdateOrderItemSku)
      if (newState.currentOrderItem) {
        newState.currentOrderItem.sku = sku
      }
      break
    // ドレッシング和える選択の更新.
    case UPDATE_DRESSING_MIXED:
      const { mixed } = (action as IUpdateDressingMixed)
      if (newState.currentOrderItem) {
        newState.currentOrderItem.dressingMixed = mixed
      }
      break
    // 注文アイテムの「スープを温める」を変更.
    case UPDATE_SOUP_WARMED:
      const { soupWarmed } = (action as IUpdateSoupWarmed)
      if (newState.currentOrderItem) {
        newState.currentOrderItem.soupWarmed = soupWarmed
      }
      break
    // 注文アイテムのオプション追加.
    case ADD_ORDER_ITEM_OPTION:
      const { optionGroup, itemOption, num } = (action as IAddOrderItemOption)
      if (newState.currentOrderItem) {
        newState.currentOrderItem = OrderUtils.addOptionToOrderItem(
          newState.currentOrderItem,
          optionGroup,
          itemOption,
          num
        )
      }
      break
    // 注文アイテムのオプション削除.
    case DELETE_ORDER_ITEM_OPTION:
      (() => {
        const { itemOption } = (action as IDeleteOrderItemOption)
        if (newState.currentOrderItem && newState.currentOrderItem.orderItemOptions) {
          newState.currentOrderItem.orderItemOptions
            = newState.currentOrderItem.orderItemOptions.filter(tmp => {
            return tmp.itemOption.id !== itemOption.id || tmp.skuId !== itemOption.item.skus[0].id
          })
        }
      })()
      break
    // 現在の注文アイテムをカートに追加
    case ADD_CART:
      (() => {
        if (newState.order && newState.currentOrderItem) {
          if (!newState.order.orderItems) {
            newState.order.orderItems = []
          }
          newState.order.orderItems.push(newState.currentOrderItem)
          newState.currentOrderItem = null
          // 商品追加時は、曜日限定アイテムである可能性もあるので、受け取り日時をリセット.
          newState.order.pickupDatetime = null
        }
      })()
      break
    // 現在の注文を破棄
    case RESET_CART:
      (() => {
        newState.order = null
        newState.currentOrderItem = null
      })()
      break
    // オーダーアイテムの数量変更
    case CHANGE_ORDER_ITEM_AMOUNT:
      (() => {
        const { orderItemKey, amount } = (action as IChangeOrderItemAmount)
        if (newState.order && newState.order.orderItems) {
          newState.order.orderItems.forEach(orderItem => {
            if (orderItem.key === orderItemKey) {
              // 1〜30個まで
              orderItem.amount = Math.max(1, Math.min(30, amount))
            }
          })
        }
      })()
      break
    // オーダーアイテムを削除
    case DELETE_ORDER_ITEM:
      (() => {
        const { orderItemKey } = (action as IDeleteOrderItem)
        if (newState.order && newState.order.orderItems) {
          newState.order.orderItems
            = newState.order.orderItems.filter(orderItem => orderItem.key !== orderItemKey)
        }
      })()
      break
    // オーダーアイテムの変更
    case CHANGE_ORDER_ITEM:
      (() => {
        const { orderItem } = (action as IChangeOrderItem)
        if (newState.order && newState.order.orderItems) {
          newState.currentOrderItem = orderItem
          newState.order.orderItems
            = newState.order.orderItems.filter(a => a.key !== orderItem.key)
        }
      })()
      break
    // 紙袋の数.
    case SET_BAG_NUM:
      (() => {
        const { num, item } = (action as ISetBagNum)
        if (newState.order) {
          if (num === 0) {
            // 0枚の場合、以前に紙袋を追加していればそれを削除.
            newState.order.orderItems = newState.order.orderItems.filter(orderItem => {
              return orderItem.item.key !== ITEM_KEY_BAG
            })
          } else {
            // 1枚以上の場合は、数量を変更または商品として追加.
            let found = false
            newState.order.orderItems = newState.order.orderItems.map(orderItem => {
              if (orderItem.item.key === ITEM_KEY_BAG) {
                orderItem.amount = num
                found = true
              }
              return orderItem
            })
            if (!found) {
              newState.order.orderItems.push({
                orderItemOptions: [],
                price: item.skus ? item.skus[0].price : 0,
                sku: item.skus ? item.skus[0] : null,  // デフォで1つ目のSKUを選択する.
                key: ItemUtils.createRandomString(),
                item,
                amount: num,
                dressingMixed: false,
                soupWarmed: false,
              })
            }
          }
        }
      })()
      break
    default:
      break
  }
  // オプション削除時など、無料アイテムの付け替えが必要な場合があるので、最新化する.
  if (newState.currentOrderItem) {
    newState.currentOrderItem = ItemUtils.refreshFreeOptions(newState.currentOrderItem)
  }
  // 注文データがある場合には、金額の再計算を行い、設定する.
  if (newState.order) {
    newState.order = ItemUtils.populateOrderTotalPrice(newState.order)
  }
  return newState
}
export default orderReducer
