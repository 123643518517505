import {combineReducers, createStore} from 'redux'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import appReducer from "./reducers/AppReducer"
import shopReducer from "./reducers/ShopReducer"
import orderReducer from "./reducers/OrderReducer"

// 永続化の設定
const rootPersistConfig = {
  key: 'hfs-web-root',
  storage,
  blacklist: [ 'app', 'shop', 'order' ]
}
const appPersistConfig = {
  key: 'hfs-web-app',
  storage,
}
const shopPersistConfig = {
  key: 'hfs-web-shop',
  storage,
}
const orderPersistConfig = {
  key: 'hfs-web-order',
  storage,
}

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  shop: persistReducer(shopPersistConfig, shopReducer),
  order: persistReducer(orderPersistConfig, orderReducer),
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)
const store = createStore(persistedReducer)
export default store

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
